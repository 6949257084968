.rs-text-grey {
    color: #808285 !important
}

.rs-text-red {
    color: #e3231a !important;
}

.rs-text-green {
    color: #228B22 !important;
}

.rs-text-white {
    color: white !important
}

.rs-text-blue {
    color: #1E90FF !important
}




.bg-gradient-info-base {
    background: linear-gradient(87deg, #ffffff 0, #F0F0F0 100%) !important;
}

.bg-gradient-info-base-branco {
    background: linear-gradient(87deg, #f8f9fe 0, #f8f9fe 100%) !important;
}

.bg-rs-red {
    background: linear-gradient(87deg, #e3231a 0, #e3231a 100%) !important;
}

.bg-RS-grey {
    /*background: linear-gradient(87deg, #808285 0, #808285 100%) !important;*/
    background-color: #808285 !important;
}

.default-rs-red {
    /*background: linear-gradient(87deg, #808285 0, #808285 100%) !important;*/
    background-color: #E2231A !important;
}

.bg-RS-grey-modal {
    /*background: linear-gradient(87deg, #808285 0, #808285 100%) !important;*/
    background-color: #F0F0F0 !important;
}

.bg-yellow-rs {
    background-color: #e9e854 !important;
}
.bg-green-rs {
    background-color: #2dce89 !important;
}
.bg-yellow-rs {
    background-color: #e9e854 !important;
}


@media (min-width:1200px) {
    .Modal-Mobile {
        min-width: 85vw
    }
}
@media (max-width:1199px) {
    .Modal-Mobile {
        min-width: 95vw
    }
}

@media (min-width:1200px) {
    .Modal-Mobile-90 {
        min-width: 90vw
    }
}

@media (max-width:1199px) {
    .Modal-Mobile-90 {
        min-width: 95vw
    }
}

